
import { defineComponent, PropType } from "vue";

import BaseButton from "@/components/common/BaseButton.vue";

import Verse from "@/models/verse";

export default defineComponent({
  name: "Verse",
  components: {
    BaseButton,
  },
  props: {
    data: {
      type: Object as PropType<Verse>,
      required: true,
    },
  },
  data() {
    return {
      verseData: this.$props.data,
    };
  },
  emits: ["loadingState"],
});
