
import { defineComponent, PropType } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import { VerseData } from "@/models/verse";

export default defineComponent({
  name: "ProfileForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseButton,
    BaseField,
  },
  props: {
    modelValue: {
      type: Object as PropType<VerseData>,
      required: true,
    },
    forUpdate: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "updateVerse"],
  computed: {
    value: {
      get(): VerseData {
        return this.modelValue;
      },
      set(value: VerseData): void {
        this.$emit("update:modelValue", value);
      },
    },
    respClasses(): Record<string, boolean> {
      var screen = ResponsiveManager.retrieveScreenSize();
      var obj = {
        desktop:
          screen === ScreenType.largeDesktop ||
          screen === ScreenType.smallDesktop,
        tablet: screen === ScreenType.tablet,
        mobile: screen === ScreenType.mobile,
      };
      return obj;
    },
    buttonText(): string {
      return this.forUpdate ? "Actualizar" : "Agregar";
    },
  },
  methods: {
    triggerAction(event: Event): void {
      event.preventDefault();
      this.$emit("updateVerse");
    },
  },

  validations() {
    return {
      value: {
        verse: { required },
        text: { required },
      },
    };
  },
});
