<template>
  <div class="donation-verse">
    <div class="global-subtitle">Siembra en el Ministerio</div>
    <p id="dnts-verse">{{ verseData.text }}</p>
    <cite id="dnts-reference">{{ verseData.verse }}</cite>
    <BaseButton text="Donar" :isSmall="true" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseButton from "@/components/common/BaseButton.vue";

import Verse from "@/models/verse";

export default defineComponent({
  name: "Verse",
  components: {
    BaseButton,
  },
  props: {
    data: {
      type: Object as PropType<Verse>,
      required: true,
    },
  },
  data() {
    return {
      verseData: this.$props.data,
    };
  },
  emits: ["loadingState"],
});
</script>

<style scoped>
.donation-verse {
  text-align: center;
}

#dnts-verse {
  font-weight: var(--f-light);
  text-align: start;
  margin-bottom: 0;
}

#dnts-reference {
  font-weight: 700;
  text-align: end;
  font-style: normal;
  margin-bottom: 8px;
  display: block;
}

@media only screen and (min-width: 768px) {
  #evts-section {
    height: 64.48%;
    width: 100%;
    display: inline-block;
  }

  #donations-section {
    max-width: 580px;
    margin: 12px 16px;
    width: 100%;
    display: inline-block;
  }
}

@media only screen and (min-width: 1280px) {
  #evts-section {
    height: 90%;
    width: 65%;
    display: inline-block;
  }

  #donations-section {
    max-width: 548px;
    margin: 0 auto;
    padding: 16px;
    width: 35%;
    display: inline-block;
  }
}
</style>
