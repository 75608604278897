<template>
  <h1 class="global-title">Editar Verso</h1>
  <a-spin :spinning="loadingState" :delay="250">
    <div class="splitted">
      <Form v-model="verseData" @update-verse="updateVerse" />
      <div class="verse-preview">
        <VersePreview :data="verseData" />
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Form from "@/components/verse/VerseForm.vue";
import VersePreview from "@/components/verse/Verse.vue";

import { VerseData } from "@/models/verse";
import Authentication from "@/controllers/auth";

import VerseController from "@/controllers/verse";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Verso Donaciones",
      description: "Editar información del verso de donaciones.",
    });
  },
  components: {
    Form,
    VersePreview,
  },
  created() {
    if (this.currentUser) {
      this.loadingState = true;
      VerseController.getVerse(this.currentUser).then((verseData) => {
        this.loadingState = false;
        if (verseData) {
          this.verseData.text = verseData.text;
          this.verseData.verse = verseData.verse;
        }
      });
    }
  },
  data() {
    return {
      loadingState: false,
      verseData: {} as VerseData,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    async updateVerse(): Promise<void> {
      this.loadingState = true;
      await VerseController.updateVerse(this.verseData, this.currentUser);
      this.loadingState = false;
    },
  },
});
</script>

<style scoped>
.verse-preview {
  color: black;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
}
</style>
