import { FirebaseUser } from "@/models/users";
import { VerseData } from "@/models/verse";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ServiceController from "./service";

export default class VerseController extends ServiceController {
  static async updateVerse(
    verseData: VerseData,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } as AxiosRequestConfig;
      await axios.put(
        `${VerseController.HOST}/verse/update`,
        verseData,
        config
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      VerseController.translateError(axiosError);
    }
  }

  static async getVerse(user: FirebaseUser): Promise<VerseData | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } as AxiosRequestConfig;
      const response = await axios.get(`${VerseController.HOST}/verse`, config);
      const verse = response.data.verse;
      return verse;
    } catch (error) {
      const axiosError = error as AxiosError;
      VerseController.translateError(axiosError);
    }
  }
}
