
import { defineComponent } from "vue";

import Form from "@/components/verse/VerseForm.vue";
import VersePreview from "@/components/verse/Verse.vue";

import { VerseData } from "@/models/verse";
import Authentication from "@/controllers/auth";

import VerseController from "@/controllers/verse";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Verso Donaciones",
      description: "Editar información del verso de donaciones.",
    });
  },
  components: {
    Form,
    VersePreview,
  },
  created() {
    if (this.currentUser) {
      this.loadingState = true;
      VerseController.getVerse(this.currentUser).then((verseData) => {
        this.loadingState = false;
        if (verseData) {
          this.verseData.text = verseData.text;
          this.verseData.verse = verseData.verse;
        }
      });
    }
  },
  data() {
    return {
      loadingState: false,
      verseData: {} as VerseData,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    async updateVerse(): Promise<void> {
      this.loadingState = true;
      await VerseController.updateVerse(this.verseData, this.currentUser);
      this.loadingState = false;
    },
  },
});
